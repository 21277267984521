import React, { useState } from "react"

export default function HeroTextAreaComponent() {
  return (
    <div className="w-full max-w-xs sm:max-w-lg">
      <div className="relative my-6">
        <textarea
          id="id-b07h"
          type="text"
          name="id-b07h"
          required
          rows="5"
          className="peer relative w-full rounded border border-slate-200 px-4 py-2 text-sm text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
        >
          The textarea element defines a multi-line text input control. It's
          often used in a form, to collect user inputs like comments or reviews.
          A text area can hold an unlimited number of characters.
        </textarea>
        <label
          htmlFor="id-b07h"
          className="absolute left-2 -top-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:top-0 before:left-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-2.5 peer-placeholder-shown:text-sm peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
        >
          Write your message
        </label>
        <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition peer-invalid:text-pink-500">
          <span> Text field with helper text </span>
          <span className="text-slate-500"> 1/10 </span>
        </small>
      </div>
    </div>
  )
}
