import React from "react"
import CopyComponent from "../../../../../../components/copy/CopyComponent"

export default function FormElementsTextareaAllPlainPreview(props) {
  const {
    FormElementsTextareaPlainBaseBasic,
    FormElementsTextareaPlainBaseHelperText,
    FormElementsTextareaPlainBaseRequired,
    FormElementsTextareaPlainBaseDisabled,
  } = props

  const {
    FormElementsTextareaPlainLgBasic,
    FormElementsTextareaPlainLgHelperText,
    FormElementsTextareaPlainLgRequired,
    FormElementsTextareaPlainLgDisabled,
  } = props

  return (
    <>
      <div className="flex w-full flex-col justify-center gap-24 md:flex-row ">
        <div className="w-full">
          <h3 className="mb-4 font-medium text-slate-500">
            Basic Text Area Base Size:
          </h3>

          <div className="mb-16 h-32">
            <CopyComponent
              copyToClipboardCode={FormElementsTextareaPlainBaseBasic}
              componentName="FormElementsTextareaPlainBaseBasic"
              extraClasses="copy-block-container"
            >
              {/*    <!-- Component: Plain base size basic textarea --> */}
              <div className="relative">
                <textarea
                  id="id-b02"
                  type="text"
                  name="id-b02"
                  rows="3"
                  placeholder="Write your message"
                  className="peer relative w-full border-b border-slate-200 px-4 py-2 text-sm text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                ></textarea>
                <label
                  for="id-b02"
                  className="absolute left-2 -top-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:top-0 before:left-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-2.5 peer-placeholder-shown:text-sm peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Write your message
                </label>
              </div>
              {/*    <!-- End Plain base size basic textarea --> */}
            </CopyComponent>
          </div>

          <div className="mb-16 h-32">
            <CopyComponent
              copyToClipboardCode={FormElementsTextareaPlainBaseHelperText}
              componentName="FormElementsTextareaPlainBaseHelperText"
              extraClasses="copy-block-container"
            >
              {/*    <!-- Component: Plain base size textarea with helper text --> */}
              <div className="relative">
                <textarea
                  id="id-b04"
                  type="text"
                  name="id-b04"
                  rows="3"
                  placeholder="Write your message"
                  className="peer relative w-full border-b border-slate-200 px-4 py-2 text-sm text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                ></textarea>
                <label
                  for="id-b04"
                  className="absolute left-2 -top-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:top-0 before:left-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-2.5 peer-placeholder-shown:text-sm peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Write your message
                </label>
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition peer-invalid:text-pink-500">
                  <span> Text field with helper text </span>
                  <span className="text-slate-500"> 1/10 </span>
                </small>
              </div>
              {/*    <!-- End Plain base size textarea with helper text --> */}
            </CopyComponent>
          </div>

          <div className="mb-16 h-32">
            <CopyComponent
              copyToClipboardCode={FormElementsTextareaPlainBaseRequired}
              componentName="FormElementsTextareaPlainBaseRequired"
              extraClasses="copy-block-container"
            >
              {/*    <!-- Component: Plain base size required textarea --> */}
              <div className="relative">
                <textarea
                  id="id-b08"
                  type="text"
                  name="id-b08"
                  required
                  rows="3"
                  className="peer relative w-full border-b border-slate-200 px-4 py-2 text-sm text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                ></textarea>
                <label
                  for="id-b08"
                  className="absolute left-2 -top-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:top-0 before:left-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-2.5 peer-placeholder-shown:text-sm peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Write your message
                </label>
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition peer-invalid:text-pink-500">
                  <span> Text field with helper text </span>
                  <span className="text-slate-500"> 1/10 </span>
                </small>
              </div>
            </CopyComponent>
            {/*    <!-- End Plain base size required textarea --> */}
          </div>

          <div className="mb-16 h-32">
            <CopyComponent
              copyToClipboardCode={FormElementsTextareaPlainBaseDisabled}
              componentName="FormElementsTextareaPlainBaseDisabled"
              extraClasses="copy-block-container"
            >
              {/*    <!-- Component: Plain base size disabled textarea --> */}
              <div className="relative">
                <textarea
                  id="id-b06"
                  type="text"
                  name="id-b06"
                  rows="3"
                  disabled
                  placeholder="Write your message"
                  className="peer relative w-full border-b border-slate-200 px-4 py-2 text-sm text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                ></textarea>
                <label
                  for="id-b06"
                  className="absolute left-2 -top-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:top-0 before:left-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-2.5 peer-placeholder-shown:text-sm peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Write your message
                </label>
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition peer-invalid:text-pink-500">
                  <span> Text field with helper text </span>
                  <span className="text-slate-500"> 1/10 </span>
                </small>
              </div>
              {/*    <!-- End Plain base size disabled textarea --> */}
            </CopyComponent>
          </div>
        </div>

        {/*  <!-- PLAIN LARGE SIZE  --> */}
        <div className="w-full">
          <h3 className="mb-4 font-medium text-slate-500">
            Basic Text Area Large Size:
          </h3>
          <div className="mb-16 h-32">
            <CopyComponent
              copyToClipboardCode={FormElementsTextareaPlainLgBasic}
              componentName="FormElementsTextareaPlainLgBasic"
              extraClasses="copy-block-container"
            >
              {/*    <!-- Component: Plain large size basic textarea --> */}
              <div className="relative">
                <textarea
                  id="id-l02"
                  type="text"
                  name="id-l02"
                  rows="3"
                  placeholder="Write your message"
                  className="peer relative w-full border-b border-slate-200 p-4 text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                ></textarea>
                <label
                  for="id-l02"
                  className="absolute left-2 -top-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:top-0 before:left-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-base peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Write your message
                </label>
              </div>
              {/*    <!-- End Plain large size basic textarea --> */}
            </CopyComponent>
          </div>

          <div className="mb-16 h-32">
            <CopyComponent
              copyToClipboardCode={FormElementsTextareaPlainLgHelperText}
              componentName="FormElementsTextareaPlainLgHelperText"
              extraClasses="copy-block-container"
            >
              {/*    <!-- Component: Plain large size textarea with helper text --> */}
              <div className="relative">
                <textarea
                  id="id-l04"
                  type="text"
                  name="id-l04"
                  rows="3"
                  placeholder="Write your message"
                  className="peer relative w-full border-b border-slate-200 p-4 text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                ></textarea>
                <label
                  for="id-l04"
                  className="absolute left-2 -top-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:top-0 before:left-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-base peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Write your message
                </label>
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition peer-invalid:text-pink-500">
                  <span>Text field with helper text </span>
                  <span className="text-slate-500"> 1/10 </span>
                </small>
              </div>
              {/*    <!-- End Plain large size textarea with helper text --> */}
            </CopyComponent>
          </div>

          <div className="mb-16 h-32">
            <CopyComponent
              copyToClipboardCode={FormElementsTextareaPlainLgRequired}
              componentName="FormElementsTextareaPlainLgRequired"
              extraClasses="copy-block-container"
            >
              {/*    <!-- Component: Plain large size required textarea --> */}
              <div className="relative">
                <textarea
                  id="id-l08"
                  type="text"
                  name="id-l08"
                  placeholder="Write your message"
                  required
                  rows="3"
                  className="peer relative w-full border-b border-slate-200 p-4 text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                ></textarea>
                <label
                  for="id-l08"
                  className="absolute left-2 -top-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:top-0 before:left-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-base peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Write your message
                </label>
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition peer-invalid:text-pink-500">
                  <span> Text field with helper text </span>
                  <span className="text-slate-500"> 1/10 </span>
                </small>
              </div>
              {/*    <!-- End Plain large size required textarea --> */}
            </CopyComponent>
          </div>

          <div className="mb-16 h-32">
            <CopyComponent
              copyToClipboardCode={FormElementsTextareaPlainLgDisabled}
              componentName="FormElementsTextareaPlainLgDisabled"
              extraClasses="copy-block-container"
            >
              {/*    <!-- Component: Plain large size disabled textarea --> */}
              <div className="relative">
                <textarea
                  id="id-l06"
                  type="text"
                  name="id-l06"
                  rows="3"
                  placeholder="Write your message"
                  disabled
                  className="peer relative w-full border-b border-slate-200 p-4 text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                ></textarea>
                <label
                  for="id-l06"
                  className="absolute left-2 -top-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:top-0 before:left-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-base peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Write your message
                </label>
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition peer-invalid:text-pink-500">
                  <span>Text field with helper text </span>
                  <span className="text-slate-500"> 1/10 </span>
                </small>
              </div>
              {/*    <!-- End Plain large size disabled textarea --> */}
            </CopyComponent>
          </div>
        </div>
      </div>
    </>
  )
}
