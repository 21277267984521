import React from "react"
import CopyComponent from "../../../../../../components/copy/CopyComponent"

export default function FormElementsTextareaAllRoundedPreview(props) {
  const {
    FormElementsTextareaRoundedBaseBasic,
    FormElementsTextareaRoundedBaseHelperText,
    FormElementsTextareaRoundedBaseRequired,
    FormElementsTextareaRoundedBaseDisabled,
  } = props

  const {
    FormElementsTextareaRoundedLgBasic,
    FormElementsTextareaRoundedLgHelperText,
    FormElementsTextareaRoundedLgRequired,
    FormElementsTextareaRoundedLgDisabled,
  } = props

  return (
    <>
      <div className="flex w-full flex-col justify-center gap-24 md:flex-row ">
        {/*  <!-- ROUNDED SMALL SIZE --> */}
        <div className="w-full">
          <h3 className="mb-4 font-medium text-slate-500">
            Outlined Text Area Base Size:
          </h3>
          <div className="mb-16 h-32">
            <CopyComponent
              copyToClipboardCode={FormElementsTextareaRoundedBaseBasic}
              componentName="FormElementsTextareaRoundedBaseBasic"
              extraClasses="copy-block-container"
            >
              {/*    <!-- Component: Rounded base size basic textarea --> */}
              <div className="relative">
                <textarea
                  id="id-01"
                  type="text"
                  name="id-01"
                  placeholder="Write your message"
                  rows="3"
                  className="peer relative w-full rounded border border-slate-200 px-4 py-2 text-sm text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                ></textarea>
                <label
                  for="id-01"
                  className="absolute left-2 -top-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:top-0 before:left-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-2.5 peer-placeholder-shown:text-sm peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Write your message
                </label>
              </div>
              {/*    <!-- End Rounded base size basic textarea --> */}
            </CopyComponent>
          </div>

          <div className="mb-16 h-32">
            <CopyComponent
              copyToClipboardCode={FormElementsTextareaRoundedBaseHelperText}
              componentName="FormElementsTextareaRoundedBaseHelperText"
              extraClasses="copy-block-container"
            >
              {/*    <!-- Component: Rounded base size textarea with helper text --> */}
              <div className="relative">
                <textarea
                  id="id-b03"
                  type="text"
                  name="id-b03"
                  rows="3"
                  placeholder="Write your message"
                  className="peer relative w-full rounded border border-slate-200 px-4 py-2 text-sm text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                ></textarea>
                <label
                  for="id-b03"
                  className="absolute left-2 -top-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:top-0 before:left-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-2.5 peer-placeholder-shown:text-sm peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Write your message
                </label>
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition peer-invalid:text-pink-500">
                  <span> Text field with helper text </span>
                  <span className="text-slate-500"> 1/10 </span>
                </small>
              </div>
              {/*    <!-- End Rounded base size textarea with helper text --> */}
            </CopyComponent>
          </div>

          <div className="mb-16 h-32">
            <CopyComponent
              copyToClipboardCode={FormElementsTextareaRoundedBaseRequired}
              componentName="FormElementsTextareaRoundedBaseRequired"
              extraClasses="copy-block-container"
            >
              {/*    <!-- Component: Rounded base size required textarea --> */}
              <div className="relative">
                <textarea
                  id="id-b07"
                  type="text"
                  name="id-b07"
                  required
                  rows="3"
                  className="peer relative w-full rounded border border-slate-200 px-4 py-2 text-sm text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                ></textarea>
                <label
                  for="id-b07"
                  className="absolute left-2 -top-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:top-0 before:left-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-2.5 peer-placeholder-shown:text-sm peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Write your message
                </label>
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition peer-invalid:text-pink-500">
                  <span> Text field with helper text </span>
                  <span className="text-slate-500"> 1/10 </span>
                </small>
              </div>
              {/*    <!-- End Rounded base size required textarea --> */}
            </CopyComponent>
          </div>

          <div className="mb-16 h-32">
            <CopyComponent
              copyToClipboardCode={FormElementsTextareaRoundedBaseDisabled}
              componentName="FormElementsTextareaRoundedBaseDisabled"
              extraClasses="copy-block-container"
            >
              {/*    <!-- Component: Rounded base size disabled textarea --> */}
              <div className="relative">
                <textarea
                  id="id-b05"
                  type="text"
                  name="id-b05"
                  rows="3"
                  disabled
                  placeholder="Write your message"
                  className="peer relative w-full rounded border border-slate-200 px-4 py-2 text-sm text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                ></textarea>
                <label
                  for="id-b05"
                  className="absolute left-2 -top-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:top-0 before:left-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-2.5 peer-placeholder-shown:text-sm peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Write your message
                </label>
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition peer-invalid:text-pink-500">
                  <span> Text field with helper text </span>
                  <span className="text-slate-500"> 1/10 </span>
                </small>
              </div>
              {/*    <!-- End Rounded base size disabled textarea --> */}
            </CopyComponent>
          </div>
        </div>

        {/*  <!-- ROUNDED LARGE SIZE --> */}

        <div className="w-full">
          <h3 className="mb-4 font-medium text-slate-500">
            Outlined Text Area Large Size:
          </h3>
          <div className="mb-16 h-32">
            <CopyComponent
              copyToClipboardCode={FormElementsTextareaRoundedLgBasic}
              componentName="FormElementsTextareaRoundedLgBasic"
              extraClasses="copy-block-container"
            >
              {/*    <!-- Component: Rounded large size basic textarea --> */}
              <div className="relative">
                <textarea
                  id="id-l01"
                  type="text"
                  name="id-l01"
                  rows="3"
                  placeholder="Write your message"
                  className="peer relative w-full rounded border border-slate-200 p-4 text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                ></textarea>
                <label
                  for="id-l01"
                  className="absolute left-2 -top-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:top-0 before:left-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-base peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Write your message
                </label>
              </div>
              {/*    <!-- End Rounded large size basic textarea --> */}
            </CopyComponent>
          </div>

          <div className="mb-16 h-32">
            <CopyComponent
              copyToClipboardCode={FormElementsTextareaRoundedLgHelperText}
              componentName="FormElementsTextareaRoundedLgHelperText"
              extraClasses="copy-block-container"
            >
              {/*    <!-- Component: Rounded large size textarea with helper text--> */}
              <div className="relative">
                <textarea
                  id="id-l03"
                  type="text"
                  name="id-l03"
                  rows="3"
                  placeholder="Write your message"
                  className="peer relative w-full rounded border border-slate-200 p-4 text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                ></textarea>
                <label
                  for="id-l03"
                  className="absolute left-2 -top-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:top-0 before:left-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-base peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Write your message
                </label>
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition peer-invalid:text-pink-500">
                  <span> Text field with helper text </span>
                  <span className="text-slate-500"> 1/10 </span>
                </small>
              </div>
              {/*    <!-- End Rounded large size textarea with helper text --> */}
            </CopyComponent>
          </div>

          <div className="mb-16 h-32">
            <CopyComponent
              copyToClipboardCode={FormElementsTextareaRoundedLgRequired}
              componentName="FormElementsTextareaRoundedLgRequired"
              extraClasses="copy-block-container"
            >
              {/*    <!-- Component: Rounded large size required textarea --> */}
              <div className="relative">
                <textarea
                  id="id-l07"
                  type="text"
                  name="id-l07"
                  required
                  placeholder="Write your message"
                  className="peer relative w-full rounded border border-slate-200 p-4 text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                ></textarea>
                <label
                  for="id-l07"
                  className="absolute left-2 -top-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:top-0 before:left-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-base peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Write your message
                </label>
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition peer-invalid:text-pink-500">
                  <span> Text field with helper text </span>
                  <span className="text-slate-500"> 1/10 </span>
                </small>
              </div>
              {/*    <!-- End Rounded large size required textarea --> */}
            </CopyComponent>
          </div>

          <div className="mb-16 h-32">
            <CopyComponent
              copyToClipboardCode={FormElementsTextareaRoundedLgDisabled}
              componentName="FormElementsTextareaRoundedLgDisabled"
              extraClasses="copy-block-container"
            >
              {/*    <!-- Component: Rounded large size disabled textarea --> */}
              <div className="relative">
                <textarea
                  id="id-l05"
                  type="text"
                  name="id-l05"
                  rows="3"
                  disabled
                  placeholder="Write your message"
                  className="peer relative w-full rounded border border-slate-200 p-4 text-slate-500 placeholder-transparent outline-none transition-all autofill:bg-white invalid:border-pink-500 invalid:text-pink-500 focus:border-emerald-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                ></textarea>
                <label
                  for="id-l05"
                  className="absolute left-2 -top-2 z-[1] cursor-text px-2 text-xs text-slate-400 transition-all before:absolute before:top-0 before:left-0 before:z-[-1] before:block before:h-full before:w-full before:bg-white before:transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-base peer-required:after:text-pink-500 peer-required:after:content-['\00a0*'] peer-invalid:text-pink-500 peer-focus:-top-2 peer-focus:cursor-default peer-focus:text-xs peer-focus:text-emerald-500 peer-invalid:peer-focus:text-pink-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400 peer-disabled:before:bg-transparent"
                >
                  Write your message
                </label>
                <small className="absolute flex w-full justify-between px-4 py-1 text-xs text-slate-400 transition peer-invalid:text-pink-500">
                  <span> Text field with helper text </span>
                  <span className="text-slate-500"> 1/10 </span>
                </small>
              </div>
              {/*    <!-- End Rounded large size disabled textarea --> */}
            </CopyComponent>
          </div>
        </div>
      </div>
    </>
  )
}
