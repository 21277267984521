import React, { useState } from "react"

// Components
import Seo from "../../../../components/seo"
import SidebarLayout from "../../../../layouts/SidebarLayout"
import Section from "../../../../components/section/Section"
import ContentNavigation from "../../../../components/scrollspy"

// Libraries / Components for Preview
import PreviewBlock from "../../../../components/preview"

import FormElementsTextareaAllPlainPreview from "../../../../library/components/form-elements/textarea/react/_preview/plain"
import FormElementsTextareaAllRoundedPreview from "../../../../library/components/form-elements/textarea/react/_preview/rounded"

import HeroTextAreaComponent from "../../../../library/components/form-elements/textarea/react/_preview/heroTextAreaComponent"
// Text Area Plain

// Base
const FormElementsTextareaPlainBaseBasicJsx = require("!!raw-loader!../../../../library/components/form-elements/textarea/react/plain/base/basic.jsx")
const FormElementsTextareaPlainBaseBasicHTML = require("!!raw-loader!../../../../library/components/form-elements/textarea/html/plain/base/basic.html")

const FormElementsTextareaPlainBaseDisabledJsx = require("!!raw-loader!../../../../library/components/form-elements/textarea/react/plain/base/disabled.jsx")
const FormElementsTextareaPlainBaseDisabledHTML = require("!!raw-loader!../../../../library/components/form-elements/textarea/html/plain/base/disabled.html")

const FormElementsTextareaPlainBaseHelperTextJsx = require("!!raw-loader!../../../../library/components/form-elements/textarea/react/plain/base/helper_text.jsx")
const FormElementsTextareaPlainBaseHelperTextHTML = require("!!raw-loader!../../../../library/components/form-elements/textarea/html/plain/base/helper_text.html")

const FormElementsTextareaPlainBaseRequiredJsx = require("!!raw-loader!../../../../library/components/form-elements/textarea/react/plain/base/required.jsx")
const FormElementsTextareaPlainBaseRequiredHTML = require("!!raw-loader!../../../../library/components/form-elements/textarea/html/plain/base/required.html")

// Lg
const FormElementsTextareaPlainLgBasicJsx = require("!!raw-loader!../../../../library/components/form-elements/textarea/react/plain/lg/basic.jsx")
const FormElementsTextareaPlainLgBasicHTML = require("!!raw-loader!../../../../library/components/form-elements/textarea/html/plain/lg/basic.html")

const FormElementsTextareaPlainLgDisabledJsx = require("!!raw-loader!../../../../library/components/form-elements/textarea/react/plain/lg/disabled.jsx")
const FormElementsTextareaPlainLgDisabledHTML = require("!!raw-loader!../../../../library/components/form-elements/textarea/html/plain/lg/disabled.html")

const FormElementsTextareaPlainLgHelperTextJsx = require("!!raw-loader!../../../../library/components/form-elements/textarea/react/plain/lg/helper_text.jsx")
const FormElementsTextareaPlainLgHelperTextHTML = require("!!raw-loader!../../../../library/components/form-elements/textarea/html/plain/lg/helper_text.html")

const FormElementsTextareaPlainLgRequiredJsx = require("!!raw-loader!../../../../library/components/form-elements/textarea/react/plain/lg/required.jsx")
const FormElementsTextareaPlainLgRequiredHTML = require("!!raw-loader!../../../../library/components/form-elements/textarea/html/plain/lg/required.html")

// Text Area Rounded

// Base
const FormElementsTextareaRoundedBaseBasicJsx = require("!!raw-loader!../../../../library/components/form-elements/textarea/react/rounded/base/basic.jsx")
const FormElementsTextareaRoundedBaseBasicHTML = require("!!raw-loader!../../../../library/components/form-elements/textarea/html/rounded/base/basic.html")

const FormElementsTextareaRoundedBaseDisabledJsx = require("!!raw-loader!../../../../library/components/form-elements/textarea/react/rounded/base/disabled.jsx")
const FormElementsTextareaRoundedBaseDisabledHTML = require("!!raw-loader!../../../../library/components/form-elements/textarea/html/rounded/base/disabled.html")

const FormElementsTextareaRoundedBaseHelperTextJsx = require("!!raw-loader!../../../../library/components/form-elements/textarea/react/rounded/base/helper_text.jsx")
const FormElementsTextareaRoundedBaseHelperTextHTML = require("!!raw-loader!../../../../library/components/form-elements/textarea/html/rounded/base/helper_text.html")

const FormElementsTextareaRoundedBaseRequiredJsx = require("!!raw-loader!../../../../library/components/form-elements/textarea/react/rounded/base/required.jsx")
const FormElementsTextareaRoundedBaseRequiredHTML = require("!!raw-loader!../../../../library/components/form-elements/textarea/html/rounded/base/required.html")

// Lg
const FormElementsTextareaRoundedLgBasicJsx = require("!!raw-loader!../../../../library/components/form-elements/textarea/react/rounded/lg/basic.jsx")
const FormElementsTextareaRoundedLgBasicHTML = require("!!raw-loader!../../../../library/components/form-elements/textarea/html/rounded/lg/basic.html")

const FormElementsTextareaRoundedLgDisabledJsx = require("!!raw-loader!../../../../library/components/form-elements/textarea/react/rounded/lg/disabled.jsx")
const FormElementsTextareaRoundedLgDisabledHTML = require("!!raw-loader!../../../../library/components/form-elements/textarea/html/rounded/lg/disabled.html")

const FormElementsTextareaRoundedLgHelperTextJsx = require("!!raw-loader!../../../../library/components/form-elements/textarea/react/rounded/lg/helper_text.jsx")
const FormElementsTextareaRoundedLgHelperTextHTML = require("!!raw-loader!../../../../library/components/form-elements/textarea/html/rounded/lg/helper_text.html")

const FormElementsTextareaRoundedLgRequiredJsx = require("!!raw-loader!../../../../library/components/form-elements/textarea/react/rounded/lg/required.jsx")
const FormElementsTextareaRoundedLgRequiredHTML = require("!!raw-loader!../../../../library/components/form-elements/textarea/html/rounded/lg/required.html")

export default function FormElementTextAreasPage() {
  const [activeTabs, setActiveTabs] = useState([
    {
      component_name: "FormElementsTextareaPlainAllPlain",
      title: "Basic text area",
      active_tab: 1,
    },
    {
      component_name: "FormElementsTextareaAllRounded",
      title: "Outline",
      active_tab: 1,
    },
  ])

  const getActiveTabs = (id, tab) => {
    let updatedActiveTabs = activeTabs.map(activeTab => {
      if (activeTab.component_name === id) {
        return { ...activeTab, active_tab: tab }
      }
      return activeTab
    })
    setActiveTabs(updatedActiveTabs)
  }

  return (
    <SidebarLayout>
      <Seo
        title="Tailwind CSS Textareas - WindUI Component Library"
        ogtitle="Tailwind CSS Textareas - WindUI Component Library"
        description="Textarea components represent a multi-line plain-text editing control, that allow to enter sizeable amount of free-form text. Built with Tailwind CSS by WindUI."
        ogdescription="Textarea components represent a multi-line plain-text editing control, that allow to enter sizeable amount of free-form text. Built with Tailwind CSS by WindUI."
        url="components/form-elements/textareas/"
        author="Wind UI"
        keywords="Tailwind CSS, Tailwind CSS Textareas, Textareas, Textarea components, WindUI, windui, wind ui"
        robots="index, follow"
        revisit_after="7 days"
      />
      <div className="min-w-0 flex-1">
        {/* Hero Section */}
        <Section ariaLabelledby="intro-heading" id="intro">
          <h1 id="intro-heading">Textareas</h1>
          <p>
            The textarea element represents a multi-line plain-text editing
            control, useful when you want to allow users to enter a sizeable
            amount of free-form text, for example, a comment on a review or
            feedback form.
          </p>

          <section
            className="not-prose max-w-full"
            aria-multiselectable="false"
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200 sm:p-16">
              <HeroTextAreaComponent />
            </div>
          </section>
        </Section>

        {/* Variations Section*/}
        <Section ariaLabelledby="variations-heading" id="variations-section">
          <h2 id="variations-heading">Variations</h2>

          {/* Text Area Plain Section */}
          <h3 id="FormElementsTextareaPlainAllPlain">Basic Text Area </h3>

          <PreviewBlock
            id="FormElementsTextareaPlainAllPlain"
            HtmlComponent={FormElementsTextareaPlainBaseBasicHTML.default}
            JsxComponent={FormElementsTextareaPlainBaseBasicJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 ">
              <FormElementsTextareaAllPlainPreview
                FormElementsTextareaPlainBaseBasic={
                  activeTabs[0].active_tab === 1
                    ? FormElementsTextareaPlainBaseBasicHTML.default
                    : FormElementsTextareaPlainBaseBasicJsx.default
                }
                FormElementsTextareaPlainLgBasic={
                  activeTabs[0].active_tab === 1
                    ? FormElementsTextareaPlainLgBasicHTML.default
                    : FormElementsTextareaPlainLgBasicJsx.default
                }
                FormElementsTextareaPlainBaseHelperText={
                  activeTabs[0].active_tab === 1
                    ? FormElementsTextareaPlainBaseHelperTextHTML.default
                    : FormElementsTextareaPlainBaseHelperTextJsx.default
                }
                FormElementsTextareaPlainLgHelperText={
                  activeTabs[0].active_tab === 1
                    ? FormElementsTextareaPlainLgHelperTextHTML.default
                    : FormElementsTextareaPlainLgHelperTextJsx.default
                }
                FormElementsTextareaPlainBaseRequired={
                  activeTabs[0].active_tab === 1
                    ? FormElementsTextareaPlainBaseRequiredHTML.default
                    : FormElementsTextareaPlainBaseRequiredJsx.default
                }
                FormElementsTextareaPlainLgRequired={
                  activeTabs[0].active_tab === 1
                    ? FormElementsTextareaPlainLgRequiredHTML.default
                    : FormElementsTextareaPlainLgRequiredJsx.default
                }
                FormElementsTextareaPlainBaseDisabled={
                  activeTabs[0].active_tab === 1
                    ? FormElementsTextareaPlainBaseDisabledHTML.default
                    : FormElementsTextareaPlainBaseDisabledJsx.default
                }
                FormElementsTextareaPlainLgDisabled={
                  activeTabs[0].active_tab === 1
                    ? FormElementsTextareaPlainLgDisabledHTML.default
                    : FormElementsTextareaPlainLgDisabledJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/*Outlined Textarea */}
          <h3 id="FormElementsTextareaAllRounded">Outlined Text Area </h3>

          <PreviewBlock
            id="FormElementsTextareaAllRounded"
            HtmlComponent={FormElementsTextareaRoundedBaseBasicHTML.default}
            JsxComponent={FormElementsTextareaRoundedBaseBasicJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 ">
              <FormElementsTextareaAllRoundedPreview
                FormElementsTextareaRoundedBaseBasic={
                  activeTabs[1].active_tab === 1
                    ? FormElementsTextareaRoundedBaseBasicHTML.default
                    : FormElementsTextareaRoundedBaseBasicJsx.default
                }
                FormElementsTextareaRoundedLgBasic={
                  activeTabs[1].active_tab === 1
                    ? FormElementsTextareaRoundedLgBasicHTML.default
                    : FormElementsTextareaRoundedLgBasicJsx.default
                }
                FormElementsTextareaRoundedBaseHelperText={
                  activeTabs[1].active_tab === 1
                    ? FormElementsTextareaRoundedBaseHelperTextHTML.default
                    : FormElementsTextareaRoundedBaseHelperTextJsx.default
                }
                FormElementsTextareaRoundedLgHelperText={
                  activeTabs[1].active_tab === 1
                    ? FormElementsTextareaRoundedLgHelperTextHTML.default
                    : FormElementsTextareaRoundedLgHelperTextJsx.default
                }
                FormElementsTextareaRoundedBaseRequired={
                  activeTabs[1].active_tab === 1
                    ? FormElementsTextareaRoundedBaseRequiredHTML.default
                    : FormElementsTextareaRoundedBaseRequiredJsx.default
                }
                FormElementsTextareaRoundedLgRequired={
                  activeTabs[1].active_tab === 1
                    ? FormElementsTextareaRoundedLgRequiredHTML.default
                    : FormElementsTextareaRoundedLgRequiredJsx.default
                }
                FormElementsTextareaRoundedBaseDisabled={
                  activeTabs[1].active_tab === 1
                    ? FormElementsTextareaRoundedBaseDisabledHTML.default
                    : FormElementsTextareaRoundedBaseDisabledJsx.default
                }
                FormElementsTextareaRoundedLgDisabled={
                  activeTabs[1].active_tab === 1
                    ? FormElementsTextareaRoundedLgDisabledHTML.default
                    : FormElementsTextareaRoundedLgDisabledJsx.default
                }
              />
            </div>
          </PreviewBlock>
        </Section>

        {/* Usage Section*/}
        <Section ariaLabelledby="usage-heading" id="usage-section">
          <h2 id="usage-heading">Usage</h2>
          <p>
            The <code>&lt;textarea&gt;</code> element defines a multi-line text
            input control. It's often used in a form, to collect user inputs
            like comments or reviews. A text area can hold an unlimited number
            of characters.
          </p>
        </Section>

        {/* Specs Section */}
        <Section ariaLabelledby="specs-heading" id="specs">
          <h2 id="specs-heading">Specs</h2>
          <p>
            Textareas comes in two basic styles (<strong>base</strong> and{" "}
            <strong>outlined</strong>) and 2 sizes:
          </p>
          <ul>
            <li>
              <strong>Base:</strong> text font size of 0.875rem (14px) and a
              label that scales from 0.875rem (14px) in font size, when the
              textarea is empty or 0.75rem (12px) when the label is floated.
              Helper text is also 0.75rem (12px) and a top and bottom margin of
              1.5rem (24px) is used to house both the floating label and the
              helper text.
            </li>
            <li>
              <strong>Large:</strong> text font size of 1rem (16px) and a label
              that scales from 1rem (16px) in font size, when the textarea is
              empty or 0.75rem (12px) when the label is floated. Helper text is
              also 0.75rem (12px) and a top and bottom margin of 1.5rem (24px)
              is used to house both the floating label and the helper text.
            </li>
          </ul>
        </Section>

        {/* Accessibility Notes Section */}
        <Section
          ariaLabelledby="accessibility-notes-heading"
          id="accessibility-notes"
        >
          <h2 id="accessibility-notes-heading">Accessibility notes</h2>

          <p>
            For better accessibility when using the{" "}
            <code>&lt;textarea&gt;</code> element:
          </p>
          <ul>
            <li>
              An id attribute to allow the <code>&lt;textarea&gt;</code> to be
              associated with a <code>&lt;label&gt;</code> element for
              accessibility purposes.
            </li>
            <li>
              A name attribute to set the name of the associated data point
              submitted to the server when the form is submitted.
            </li>
            <li>
              Rows and cols attribute to allow you to specify an exact size for
              the <code>&lt;textarea&gt;</code> to take.{" "}
            </li>
            <li>
              Default content is entered between the opening and closing tags.
            </li>
          </ul>
        </Section>
      </div>

      <ContentNavigation variations={activeTabs} />
    </SidebarLayout>
  )
}
